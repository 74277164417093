<template>
  <div>
    <div class="registration-form">
      <h1>Love Souls Relentlessly</h1>
      <form @submit.prevent="submitForm" class="form-container">
        <div v-if="guestCGMembersCount != 0" class="count-display">
          <p>{{ guestCGMembersCount }} Names Submitted </p>
          <p v-if="guestCGMembersInvitedCount >= 50">{{ guestCGMembersInvitedCount }} Successful Invites</p>
        </div>

        <!-- User Type Selection -->
        <div class="form-group">
          <div class="radio-wrapper">
            <span class="radio-label">I am</span>
            <span class="radio-group">
              <label class="radio-label">
                <input type="radio" v-model="formData.isGuest" :value="false" required />
                In a CG
              </label>
              <label class="radio-label">
                <input type="radio" v-model="formData.isGuest" :value="true" required />
                Not in a CG
              </label>
            </span>
          </div>
          <p v-if="formData.isGuest === null && isFormSubmitted" class="error-message">Please select an option.</p>
        </div>

        <!-- Area/Ministry Dropdown (Only for Non-Guests) -->
        <div v-if="!formData.isGuest && formData.isGuest != null" class="form-group">
          <div class="flex-container">
            <label for="dropdown">Area/Ministry</label>
            <select id="dropdown" v-model="formData.selectedOptionArea" @change="fetchCGGroups" required>
              <option v-for="item in areaDropDownList" :key="item.id" :value="item.AreaMinistryID">
                {{ item.AreaMinistryName }}
              </option>
            </select>
          </div>
        </div>

        <!-- Connect Group Dropdown (Only for Non-Guests) -->
        <div v-if="!formData.isGuest && formData.selectedOptionArea" class="form-group">
          <div class="flex-container">
            <label for="cg-group">Connect Group</label>
            <select id="cg-group" v-model="formData.selectedOption" required>
              <option v-for="item in cgDropDownList" :key="item.id" :value="item.CGgroupID">
                {{ item.CGgroupName }}
              </option>
            </select>
          </div>
        </div>

        <!-- Centre Dropdown (Only for Guests) -->
        <div v-if="formData.isGuest" class="form-group">
          <div class="flex-container">
            <label for="dropdown">I attend</label>
            <select id="dropdown" v-model="formData.selectedCentre" required>
              <option v-for="item in centreOptions" :key="item.CentreID" :value="item.CentreID">
                {{ item.CentreName }}
              </option>
            </select>
          </div>
        </div>

        <!-- Name Input Field (Always Required) -->
        <div v-if="formData.isGuest != null" class="form-group">
          <div class="flex-container">
            <label for="inviter-name">My Name</label>
            <input v-model="formData.inviterName" type="text" id="inviter-name" required />
          </div>
        </div>

        <!-- Family/Friend Names -->
        <div v-if="formData.isGuest != null" class="form-group">
          <label for="friend-names">My Family/Friend Names</label>
          <div class="friend-names-container">
            <div v-for="(friend, index) in formData.friendNames" :key="index" class="friend-input">
              <input v-model="formData.friendNames[index]" type="text" :id="'friend-name-' + index" required />
              <div class="button-group">
                <button v-if="formData.friendNames.length > 1 && index !== formData.friendNames.length - 1"
                  @click="removeFriend(index)" type="button" class="remove-friend-button">–</button>
                <button v-if="index === formData.friendNames.length - 1 && formData.friendNames.length < 10"
                  @click="addFriend" type="button" class="add-friend-button">+</button>
                <button v-if="formData.friendNames.length > 1 && index === formData.friendNames.length - 1"
                  @click="removeFriend(index)" type="button" class="remove-friend-button">–</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <button type="submit" class="submit-button">Submit</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegistrationForm',
  data() {
    return {
      formData: {
        isGuest: null, // Determines if the user is a guest
        selectedOption: null,
        selectedOptionArea: null,
        selectedCentre: null,
        fullName: '',
        inviterName: '',
        friendNames: ['']
      },
      cgDropDownList: [],
      areaDropDownList: [],
      centreOptions: [],
      isFormSubmitted: false, // Flag to track if form has been submitted
      guestCGMembersCount: 0, // Add this property to store the count
      guestCGMembersInvitedCount: 0
    };
  },
  mounted() {
    this.fetchAreas();
    this.fetchCentres();
    this.fetchGuestCGMembersCount();
    this.fetchGuestCGMembersInvitedCount();
  },
  methods: {
    addFriend() {
      if (this.formData.friendNames.length < 10) {
        this.formData.friendNames.push('');
      }
    },
    removeFriend(index) {
      if (this.formData.friendNames.length > 1) {
        this.formData.friendNames.splice(index, 1);
      }
    },
    async fetchAreas() {
      try {
        const response = await axios.get('https://loveprayerwall.trinity.sg/api/areaministry');
        this.areaDropDownList = response.data;
      } catch (error) {
        console.error('Error fetching areas:', error);
      }
    },
    async fetchCGGroups() {
      try {
        const areaID = this.formData.selectedOptionArea;
        if (areaID) {
          const response = await axios.get(`https://loveprayerwall.trinity.sg/api/cggroups/${areaID}`);
          this.cgDropDownList = response.data;
        } else {
          this.cgDropDownList = []; // Clear CG groups if no area is selected
        }
      } catch (error) {
        console.error('Error fetching CG groups:', error);
      }
    },
    async fetchCentres() {
      try {
        const response = await axios.get('https://loveprayerwall.trinity.sg/api/centres');
        this.centreOptions = response.data;
      } catch (error) {
        console.error('Error fetching centres:', error);
      }
    },
    async fetchGuestCGMembersCount() {
      try {
        const response = await axios.get('https://loveprayerwall.trinity.sg/api/guestcgmembers/count');
        this.guestCGMembersCount = response.data.count;
      } catch (error) {
        console.error('Error fetching guest CG members count:', error);
      }
    },
    async fetchGuestCGMembersInvitedCount() {
      try {
        const response = await axios.get('https://loveprayerwall.trinity.sg/api/guestcgmembers/countinvites');
        this.guestCGMembersInvitedCount = response.data.count;
      } catch (error) {
        console.error('Error fetching guest CG members invite count:', error);
      }
    },
    async submitForm() {
      this.isFormSubmitted = true; // Set flag to true on form submission

      // Check if isGuest is null or undefined
      if (this.formData.isGuest === null || this.formData.isGuest === undefined) {
        return; // Do not submit if isGuest is not selected
      }

      // Define data based on user type
      let data = {};
      const url = this.formData.isGuest
        ? 'https://loveprayerwall.trinity.sg/api/guestregister'
        : 'https://loveprayerwall.trinity.sg/api/register';

      if (this.formData.isGuest) {
        data = {
          selectedCentre: this.formData.selectedCentre,
          friendNames: this.formData.friendNames,
          inviterName: this.formData.inviterName
        };
      } else {
        data = {
          selectedAreaMinistry: this.formData.selectedOptionArea,
          selectedCGgroup: this.formData.selectedOption,
          friendNames: this.formData.friendNames,
          inviterName: this.formData.inviterName
        };
      }

      try {
        const response = await axios.post(url, data);
        this.$router.push('/success');
        console.log(response.data);
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('Registration failed.');
      }
    },
  },
};
</script>

<style scoped>
.background-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure container covers at least the full viewport height */
  background-image: url('../assets/background.png');
  /* Adjust path as needed */
  background-size: cover;
  /* Stretch the image to cover the entire container */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Prevent image repetition */
}

.content {
  flex: 1;
  /* Allow content to grow and push other content down if needed */
}

@font-face {
  font-family: 'Acumin Pro Wide';
  src: url('../assets/Acumin Pro Wide Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kalista Serif';
  src: url('../assets/KalistaSerif-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Basic reset for form elements */
form {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container for the form */
.registration-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.count-display {
  font-family: Acumin Pro Wide;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%;
  letter-spacing: -0.65px;
  color: white;
}

/* Title styling */
h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-family: Kalista Serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
  letter-spacing: -0.64px;
}

/* Flex container for the form fields */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Style for each form group */
.form-group {
  font-family: 'Acumin Pro Wide';
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
}

/* Align labels to the far left */
.form-group label {
  font-family: 'Acumin Pro Wide';
  font-size: 16px;
  margin: 0;
  text-align: left;
  display: block;
  color: white;
  /* Ensures label takes full width */
}

/* Styling for labels and input elements */
input[type="text"],
select {
  font-family: 'Acumin Pro Wide';
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  color: black;
  /* Ensure input fields take full width */
}

/* Style for the dropdowns */
select {
  width: 100%;
}

/* Style for the radio options wrapper */
.radio-wrapper {
  display: flex;
  align-items: center;
  gap: 80px;
}

/* Flex container for the radio buttons and labels */
.radio-group {
  display: flex;
  gap: 20px;
}

/* Style for radio buttons and labels */
.radio-label {
  display: flex;
  align-items: center;
}

.radio-label input[type="radio"] {
  margin-right: 5px;
}

/* Style for the submit button */
.submit-button {
  font-family: Acumin Pro Wide;
  font-size: 16px;
  background-color: #3b0d18;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #2a0a12;
}

/* Style for error message */
.error-message {
  color: red;
  font-size: 14px;
}

/* Style for dynamic friend name input and buttons */
.friend-names-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.friend-input {
  display: flex;
  align-items: center;
  gap: 10px;
}

.friend-input input {
  flex: 1;
}

.friend-names-container button {
  align-self: center;
  margin: 5px 0;
}

/* Style for the button group */
.button-group {
  display: flex;
  gap: 5px;
}

/* Style for the add and remove friend buttons */
.add-friend-button,
.remove-friend-button {
  font-family: 'Acumin Pro Wide';
  background-color: #3b0d18;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-friend-button:hover,
.remove-friend-button:hover {
  background-color: #2a0a12;
}

/* Ensure label and select are aligned on the same line */
.flex-container {
  display: flex;
  align-items: center;
  /* Center items vertically */
  gap: 10px;
  margin-bottom: 7px;
  /* Optional: Adjust spacing between label and select */
}

/* Style for labels */
.flex-container label {
  font-family: 'Acumin Pro Wide';
  font-size: 16px;
  color: white;
  margin: 0;
  width: 110px;
  /* Adjust width as needed */
  text-align: left;
}

/* Style for select element */
.flex-container select {
  font-family: 'Acumin Pro Wide';
  flex: 1;
  /* Allow select to take remaining space */
  padding: 10px;
  font-size: 16px;
  color: black;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.flex-container input {
  font-family: 'Acumin Pro Wide';
  flex: 1;
  /* Allow select to take remaining space */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Mobile responsive adjustments */
@media (max-width: 767px) {
  h1 {
    font-size: 24px;
    /* Adjust size for smaller screens */
    line-height: 150%;
  }

  .count-display {
    font-family: Acumin Pro Wide;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
    letter-spacing: -0.65px;
  }

  .form-group {
    font-size: 16px;
  }

  .submit-button {
    font-size: 13px;
    padding: 8px 16px;
  }

  .radio-wrapper {
    display: flex;
    align-items: center;
    gap: 50px;
  }
}
</style>