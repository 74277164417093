<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal">
      <h3>Confirm Removal?</h3>
      <p>{{ friendName }}</p>
      <div class="modal-buttons">

        <button class="cancelButton" @click="cancelDelete">No</button>
        <button class="confirmButton" @click="confirmDelete">Yes</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['visible', 'friendName'],
  methods: {
    confirmDelete() {
      this.$emit('confirm');
    },
    cancelDelete() {
      this.$emit('cancel');
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust this value to increase/decrease space between buttons */
  margin-top: 20px;
}

.confirmButton {
  font-family: Acumin Pro Wide;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 8px 15px;
  background-color: #3b0d18;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancelButton {
  font-family: Acumin Pro Wide;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 8px 15px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid #3b0d18; /* Adds an outline with the specified color */
}
</style>