<template>
    <div class="success-page">
      <h1>Thank you for your submission</h1>
      <div class="link-container">
        <router-link to="/">Submit more names</router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SuccessPage',
  };
  </script>
  
  <style scoped>
  .success-page {
    text-align: center;
    padding: 20px;
    margin: 20px;
  }
  
  .success-page h1 {
    color: #3b0d18;
  }
  
  .success-page p {
    color: #333;
  }
  
  .link-container {
    margin-top: 20px; /* Space above the link container */
  }
  
  .link-container a {
    color: #007bff;
    text-decoration: none;
    margin: 0 10px; /* Space between the links */
  }
  
  .link-container a:hover {
    text-decoration: underline;
  }
  </style>